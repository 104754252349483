/*== Globals ==*/

*{
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}
a{
    text-decoration: none;
    color: white
}
a:hover{
    color: red;
}
#root{
    width: 100%;
}
body{
    width: 100vw;
    background-color:white;
    overflow-x: hidden;
    padding-top: 10px;
}

h1{
    margin: 8px;
    padding: 5px;
    font-size: 5em;
    color: white;
}
h2,h3,h4,h5{
    margin: 8px;
    padding: 5px;
    font-size: 3em;
    color: white;
}
p{
    margin: 5px;
    padding: 5px;
    font-size: 1.5em;
    color: white;
}


  

/*== ScrollBar ==*/
/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(29,29,135);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: rgb(13,225,225);
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    
    
/*== Classes ==*/

/*== Alex Pointing ==*/
.alexPointing{
    grid-area: 3/1/-1/-1;
    align-self: end;
    width:350px;
    height: 0px;
    margin-bottom: 15px;
}
#btSite:hover{
    cursor: pointer;
}
#btCRM:hover{
    cursor: pointer;
}
.dialog-1{
    height: 50px;
    width: 150px;
    background-color: lightblue;
    position: relative;
    border-radius: 10%;
}
.left-point{
	width: 0;
	height: 0;
	border-left: 2vh solid transparent;
	border-right: 2vh solid transparent;
	border-top: 10vh solid lightblue;
	position: relative;
	top: 45%;
	left: -10%;
	transform: rotate(60deg);
}

.alexPointingVcard{
    position: absolute;
    display: flex;
    right:0;
    top: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    justify-content: right;
    background-color: rgb(0,140,255,50%);
    z-index: 0;
}
.alexPointingVcard img{
    width: 300px;
    height: 225px;
    margin-right: 25px;
}

/*=================================================*/

.flexBoxResume{
    grid-area: 2/1/1/-1;
    display: flex;
    background: white;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 25px;
}
.resumeArea{
    display: flex;
}
.row{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 1;
    max-height: 500px;
    width: 100%;
}

.grid_obj_col1{
    grid-area: 1/1/1/1;
}

.grid_obj_centered{
    grid-area: 1/1/1/-1;
    justify-self: center;
}
.gridArea{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-template-rows: repeat(3,minmax(312px, 33.3vh));
}
.gridArea_02{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-template-rows: repeat(3,minmax(312px, 33.3vh));
}
.topRow{    
    grid-area: 1/1/1/-1;
    text-align: center;
    justify-self: center;
    align-self: center;
    color: white;
}
.topRowCenter{
    grid-area: 1/2/1/5;
    text-align: center;
    justify-self: center;
    align-self: top;
    color: white;
}
.midRowCenter{
    grid-area: 2/2/2/5;
    text-align: center;
    justify-self: center;
    align-self: center;
    color: white;
}
.bottomRowCenter{
    grid-area: 3/2/4/5;
    text-align: center;
    justify-self: center;
    align-self: center;
    color: white;
}
.leftElemTop{
    grid-area: 1/1/1/3;
    text-align: left;
    justify-self: flex-start;
    color: white;
}
.leftElemMid{
    grid-area: 1/3/1/3;
    text-align: left;
    justify-self: flex-start;
    color: white;
}
.leftElemBottom{
    grid-area: 3/1/3/1;
    text-align: left;
    justify-self: flex-start;
    color: white;
}
.centerElemTop{
    grid-area: 1/1/1/1;
    text-align: center;
    justify-self: center;
    align-self: center;
    color: white;
}
.centerElemMid{
    grid-area: 2/3/2/3;
    text-align: center;
    justify-self: center;
    align-self: center;
    color: white;
}
.centerElemBottom{
    grid-area: 3/2/3/3;
    text-align: center;
    justify-self: center;
    align-self: center;
    color: white;
}
.gridPhoto{
    grid-area: 2/1/-1/-1;
    justify-self: center;
    margin: 16px
}
.gridPhoto img{
    height:100%
}

.alexPhotoContainer{
    position: fixed;
    inset: 0 0 0 0;
    background-repeat: no-repeat;
    background-position: top;
    background-image: url("./images/Alex Holding Sign.png");
    z-index: -1;
}
.videoContainer{
    position: absolute;
    inset: 3vh 0 15vh 0;
    display: grid;
    grid-template-columns: auto;
    width: 100%;
}
.videoContainer div{
    grid-area: 1/-1;
    align-self: center;
}

.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.sticky-header__logo {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}
.sticky-header__logo img{
    width: 50px;
}

.sticky-header__hamburger {
    width: 32px;
    height: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    background-color: white;
    border-radius: 15px;
    padding: 5px;
}

.sticky-header__hamburger span {
    width: 100%;
    height: 3px;
    margin: 4px 0;
    background-color: #333;
}

.sticky-header__menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80px;
    right: 0;
    width: 250px;
    height: auto;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
}
.sticky-header_content{
    padding-top: 10px;
    padding-bottom: 10px;
}
.topTracker{
    width: 100%;
    position: fixed;
    inset: 0 0 auto;
}
/*== Css Components As Classes ==*/
.txt_centered{
    text-align: center;
}

@media (max-width: 725px) {
/*===@MEDIA GLOBALS===*/
    h1{
        margin: 8px;
        padding: 5px;
        font-size: 2em;
        color: white;
    }
    h2,h3,h4,h5{
        margin: 8px;
        padding: 5px;
        font-size: 1em;
        color: white;
    }
    p{
        margin: 5px;
        padding: 5px;
        font-size: 1.5em;
        color: white;
    }
/*===@MEDIA CLASSES===*/
    .gridArea{
        display: grid;
        grid-template-columns: repeat(5,1fr);
        grid-template-rows: repeat(2,minmax(50vh, auto));
    }
    .gridArea_02{
        display: grid;
        grid-template-columns: repeat(5,1fr);
        grid-template-rows: repeat(2,minmax(33.3vh, auto));
    }
    .topRowCenter{
        grid-area: 1/1/1/-1;
        text-align: center;
        justify-self: center;
        align-self: bottom;
        color: white;
    }
    .midRowCenter{
        grid-area: 2/1/2/-1;
        text-align: center;
        justify-self: center;
        align-self: bottom;
        color: white;
    }
    .bottomRowCenter{
        grid-area: 3/2/4/5;
        text-align: center;
        justify-self: center;
        align-self: bottom;
        color: white;
    }
    .leftElemTop{
        grid-area: 1/1/1/3;
        text-align: left;
        justify-self: flex-start;
        color: white;
    }
    .leftElemMid{
        grid-area: 1/3/1/3;
        text-align: left;
        justify-self: flex-start;
        color: white;
    }
    .leftElemBottom{
        grid-area: 3/1/3/1;
        text-align: left;
        justify-self: flex-start;
        color: white;
    }
    .centerElemTop{
        grid-area: 1/1/1/1;
        text-align: center;
        justify-self: center;
        align-self: center;
        color: white;
    }
    .centerElemMid{
        grid-area: 2/3/2/3;
        text-align: center;
        justify-self: center;
        align-self: center;
        color: white;
    }
    .centerElemBottom{
        grid-area: 3/2/3/3;
        text-align: center;
        justify-self: center;
        align-self: center;
        color: white;
    }
    .gridPhoto{
        grid-area: 2/1/-1/-1;
        justify-self: center;
        margin: 16px
    }
    .gridPhoto img{
        max-width: 100vw;
        height: auto    ;
    }
}